@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
  --successColor: #43a047;
  --infoColor: #1976d2;
  --errorColor: #e53935;
  --warningColor: #ffc107;
  --disabledColor: #bdbdbd;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  overflow-y: hidden;
}

.search-text-field input {
  padding-top: 7.5px !important;
  padding-bottom: 7.5px !important;
  font-size: 0.85em;
}

.search-text-field .MuiOutlinedInput-inputMarginDense {
  padding-top: 7.5px !important;
  padding-bottom: 7.5px !important;
  font-size: 0.85em;
}

.filter-text-field .MuiOutlinedInput-input {
  padding-top: 7.5px !important;
  padding-bottom: 7.5px !important;
  font-size: 0.85em;
}

.active-state-label {
  font-size: 0.7em;
  border: solid 1px var(--successColor);
  padding: 3px;
  border-radius: 3px;
  color: var(--successColor);
  font-weight: 600;
}

.info-state-label {
  font-size: 0.7em;
  border: solid 1px var(--infoColor);
  padding: 3px;
  border-radius: 3px;
  color: var(--infoColor);
  font-weight: 600;
}

.warning-state-label {
  font-size: 0.7em;
  border: solid 1px var(--warningColor);
  padding: 3px;
  border-radius: 3px;
  color: var(--warningColor);
  font-weight: 600;
}

.disabled-state-label {
  font-size: 0.7em;
  border: solid 1px var(--disabledColor);
  padding: 3px;
  border-radius: 3px;
  color: var(--disabledColor);
  font-weight: 600;
}

.inactive-state-label {
  font-size: 0.7em;
  border: solid 1px var(--errorColor);
  padding: 3px;
  border-radius: 3px;
  color: var(--errorColor);
  font-weight: 600;
}

.secondary-gradient {
  background: linear-gradient(180deg, #FFE57F 0, #FFA000 50%, #FFA000);
}

.auth-input input {
  font-size: 1.75em;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-small-p .MuiAutocomplete-inputRoot {
  padding: 2px 6px !important;
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.button-p-0 {
  padding: 0 !important;
}

.document-primary-outline {
  box-shadow: 2px 0 0 #D40511 inset;
}

.file-upload-container {
  border: dashed 2px #c1c1c1;
  border-radius: 10px;
  transition: 0.25s;
}

.file-upload-container:hover {
  transform: scale(0.95);
}

.file-description-input input {
  font-size: 0.8em;
}

.hover-bg-grey-100:hover {
  background-color: #fafafa;
}

.autocomplete-p-s {
  min-width: 0 !important;
  padding-right: 0 !important;
}

.quote {
  font-size: 0.75em !important;
  margin-top: 6px !important;
  border-left: 2px solid #ddd !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 10px !important;
  color: #aaa !important;
  font-style: italic !important;
}

.date-input .MuiOutlinedInput-adornedStart {
  padding-left: 0px !important;
}

.document-link {
  color: #2196f3 !important;
  text-decoration: underline #2196f3;
}

.hover-list-item:hover .hover-icon {
  opacity: 1;
}

.hover-icon {
  transition: 0.25s;
  opacity: 0;
}

.warning-arrow-tooltip {
  color: #fff59d !important;
}

.warning-tooltip {
  background-color: #fff59d !important;
}

.info-arrow-tooltip {
  color: #FFF !important;
}

.info-tooltip {
  background-color: #FFF !important;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.glass-md {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 4px 2px 8px -2px rgba(31, 38, 135, 0.1);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  border-radius: 50%;
}

.info-notification {
  background-color: var(--infoColor);
  color: #FFF;
  transform: translateX(18px) translateY(-10px) scale(0.9) !important;
}

.glass-paper {
  background: rgba(255, 255, 255, 0.8) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(15px);
  transition: 0.5s;
}

.MuiPickersDay-dayDisabled {
  background-color: transparent !important;
}

.MuiPickersBasePicker-pickerView {
  overflow-y: hidden;
}

.MuiDialog-paperWidthLg {
  max-width: 1200px !important;
}